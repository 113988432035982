import React from "react"

import { withHtml } from "@components/logic"
import { description__white } from "@styles/typography.module.scss"
import { hero } from "../styles/blog.module.scss"
import HeroImage from "../img/laptop.jpg"

const Heading = withHtml("h1")

interface IBlogHero {
  title: string
  featuredImage?: string
}

const BlogHero: React.FC<IBlogHero> = ({
  title,
  featuredImage = HeroImage,
}): JSX.Element => (
  <div className={hero} style={{ backgroundImage: `url(${featuredImage})` }}>
    <Heading className={description__white}>{title}</Heading>
  </div>
)

export default BlogHero
