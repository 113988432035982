import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import { ResizedImage as Image } from "@components/shared"
import { withHtml } from "@components/logic"

import { heading } from "@styles/typography.module.scss"
import {
  recommendations,
  recommendations__list,
} from "../styles/post.module.scss"
import { IPostElement } from "../types"

const Title = withHtml("h4")

interface IRecommendations {
  currentPost: string
  posts: IPostElement[]
}

const Recommendations: React.FC<IRecommendations> = ({
  currentPost,
  posts,
}): JSX.Element => {
  const [recommendedPosts, setRecommendedPosts] = useState<IPostElement[]>([])

  useEffect(() => {
    const setPosts = () =>
      posts.forEach((post) => {
        if (post.slug !== currentPost) {
          setRecommendedPosts((prevState) => [...prevState, post])
        }
      })

    setPosts()
  }, [currentPost])

  return (
    <div className={recommendations}>
      <h3 className={heading}>Przeczytaj również</h3>

      <div className={recommendations__list}>
        {recommendedPosts.map(({ slug, title, featuredImage }, i) => {
          if (i > 2) return

          return (
            <Link to={`/blog/${slug}/`} key={slug}>
              <Image size="600w" {...featuredImage} />
              <Title>{title}</Title>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Recommendations
