import React, { useState } from "react"
import { WPApi } from "@api"
import { TextInput } from "@components/shared"
import { withHtml } from "@components/logic"
import { commentPost, retrievePostComments } from "./logic"
import {
  comments,
  comments__list,
  comments__list_comment,
} from "../styles/comments.module.scss"

const Content = withHtml("p")

const ContextComment = ({ dateGmt, author, content }) => {
  return (
    <div>
      <h5>{JSON.stringify(dateGmt)}</h5>
      <h5>{JSON.stringify(author)}</h5>
      <Content>{content}</Content>
    </div>
  )
}

const SingleComment = ({ author_name, date, content }) => {
  return (
    <div className={comments__list_comment}>
      <div className="header">
        <h6>
          <strong>{author_name}</strong>
        </h6>
        <h5 className="date">{date.replace("T", " ")}</h5>
      </div>
      <Content>{content.rendered}</Content>
    </div>
  )
}

class CommentSection extends React.Component {
  constructor(props) {
    super(props)
    this.sendComment = this.sendComment.bind(this)
    this.changeState = this.changeState.bind(this)
  }
  state = {
    comments: [],
    name: "",
    email: "",
    comment: "",
    msg: "",
    imBusy: true,
  }

  componentDidMount() {
    this.getFreshComments()
  }

  getFreshComments() {
    retrievePostComments(this.props.post.databaseId).then((data) => {
      this.setState({
        comments: data,
        imBusy: false,
      })
    })
  }

  sendComment() {
    WPApi.commentPost(
      Number(this.props.post.databaseId),
      this.state.name,
      this.state.email,
      this.state.comment
    )
      .then((data) => {
        this.setState({
          name: "",
          email: "",
          comment: "",
          msg:
            "Dziękujemy za komentarz, będzie on widoczny po zaakceptowaniu przez administratora.",
        })

        return this.getFreshComments()
      })
      .catch((data) => {
        this.setState({
          msg: data.data.message,
        })
      })
  }

  changeState = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    })
  }

  render() {
    const { post } = this.props
    let commentsList = this.state.comments
    const contextComments = post.comments.nodes
    return (
      <div>
        <div className={comments}>
          <div className="container">
            <form
              onSubmit={(ev) => {
                ev.preventDefault()
              }}
            >
              <div className="row">
                <div className="col-12">
                  <textarea
                    value={this.state.comment}
                    name="comment"
                    placeholder={"Podziel się swoją opinią"}
                    onChange={this.changeState}
                  />
                  <TextInput
                    value={this.state.name}
                    name="name"
                    placeholder={"Twoje imię i nazwisko"}
                    onChange={this.changeState}
                  />
                  <TextInput
                    value={this.state.email}
                    name="email"
                    placeholder={"Twój adres email"}
                    onChange={this.changeState}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button onClick={this.sendComment}>Dodaj komentarz</button>
                  <h4>{this.state.msg}</h4>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          {contextComments.length > 0 && this.state.comments.length == 0
            ? contextComments.map((el, k) => <ContextComment {...el} key={k} />)
            : null}
        </div>
        {this.state.comments.length > 0 ? (
          <div className={comments__list}>
            <div className="container">
              {commentsList.map((el, k) => (
                <SingleComment {...el} key={k} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default CommentSection
