import React from "react"
import moment from "moment"
import {
  ResizedImage as Image,
  AnimatedSection,
  AnimatedLink,
} from "@components/shared"
import { withHtml } from "@components/logic"
import {
  postItem,
  post,
  post__wrapper,
  post__content,
  post__date,
  post__wrapper__text,
  post__wrapper__text__img,
  post__wrapper__img,
  post__wrapper__img__wrap,
  post__tags,
  post__tags__wrap,
} from "../styles/postitem.module.scss"

const Title = withHtml("h3")

const Tags = ({ tags }) => {
  const { nodes } = tags
  return (
    <div className={post__tags__wrap}>
      {nodes.map(({ slug, name }, i) => {
        return (
          <AnimatedLink to={`/tags/${slug}/`} key={i}>
            {"#" + name}
          </AnimatedLink>
        )
      })}
    </div>
  )
}

const PostItem = ({ title, slug, date, featuredImage, tags, categories }) => {
  let language = "/pl"
  if(typeof window !== "undefined"){
    language = localStorage.getItem('language')
  }
  return (
    <div className={postItem}>
      <AnimatedSection as={"article"} className={post__wrapper}>
        <AnimatedLink to={`${language}/blog/${slug}/`} className={post}>
          <div className={post__wrapper__img}>
            <Image
              {...featuredImage}
              className={post__wrapper__img__wrap}
              size={"600w"}
            />
          </div>
          <div className={post__wrapper__text}>
            <div className={post__date}>
              {moment(date).format("DD.MM.YYYY")}
            </div>
            <div className={post__content}>
              <Title>{title}</Title>
            </div>
            <Tags tags={tags} className={post__tags} />
            <div className={post__wrapper__text__img}></div>
          </div>
        </AnimatedLink>
      </AnimatedSection>
    </div>
  )
}
export default PostItem
